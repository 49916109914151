import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
	tableContainer: {
		width: '100%',
		'& .MuiTableCell-body > .MuiFormControl-root': {
			width: '100%',
		},
	},
	pilabsRoundedBtn: {
		background: 'var(--pi-labs-blue)',
		zIndex: 100,
		color: '#fff',
		height: 'fit-content',
		fontSize: '0.78rem',
		fontWeight: 600,
		padding: '.75rem 1rem',
		lineHeight: 'inherit',
		'&:hover': {
			background: 'var(--pi-labs-dark-blue)',
		},
	},
	tableToolbar: {
		display: 'flex',
		flexWrap: 'wrap',
		height: '5.3rem',
		alignItems: 'space-between',
		justifyContent: 'space-between',
		padding: '2.5rem 0',
		'& .st-table-heading': {
			fontSize: '1.5rem',
			fontWeight: 700,
		},
	},
});

export default useStyles;
