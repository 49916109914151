import React from 'react';
import './TempLandingPage.css';
import Layout from '../layout/Layout';

const TempLandingPage = () => {
	return (
		<Layout>
			<div
				style={{
					height: '90vh',
					display: 'flex',
					justifyContent: 'center',
					width: '100%',
					alignItems: 'center',
				}}
			>
				<div id="y-body">
					<div id="y-head">
						<div id="left-eye"></div>
						<div id="right-eye"></div>
						<div id="mouth"></div>
						<div id="left-ear"></div>
						<div id="right-ear"></div>
					</div>
					<div id="cup">
						<div id="steam">~</div>
					</div>
				</div>
			</div>
		</Layout>
	);
};

export default TempLandingPage;
