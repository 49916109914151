import React from 'react';

const Toolbar = ({ pageTitle = 'Safety Tag OTA Tokens', ...props }: any) => {
	return (
		<div style={{ height: 'auto' }} className={props.className}>
			<div className="st-table-heading">{pageTitle}</div>
			{props.children}
		</div>
	);
};

export default Toolbar;
