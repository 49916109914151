import { API_TOKEN } from './constants';
import axios from './interceptors';
import ITokenRequestBody from '../model/Token';

export const fetchApiTokens = () => {
	return axios.get(`${API_TOKEN}`);
};

export const createApiToken = (content: ITokenRequestBody) => {
	// The backend is reading the name from the customerId.
	// The spread will not be necessary if backend changes to read from name.
	return axios.post(`${API_TOKEN}`, { ...content, customerId: content.name });
};

export const deleteApiToken = async (tokenId: string) => {
	return await axios.delete(`${API_TOKEN}/${tokenId}`);
};
