import React from 'react';
import { useSelector } from 'react-redux';
import Layout from '../layout/Layout';
import IStore from '../../store/IStore';
import User from '../../model/User';
import st from '../../assets/safety-tag.png';

const HomePage = () => {
	const user = useSelector<IStore, User | null | undefined>((state) => state.user.info);
	return (
		<Layout>
			<div style={{ display: 'grid', gridTemplateColumns: '50% 50%', width: '100%' }}>
				<div style={{ display: 'flex', width: '100%', justifyContent: 'flex-end', padding: '2rem 0' }}>
					<img width={450} src={st} alt="Safety Tag" />
				</div>
				<div
					style={{
						display: 'grid',
						padding: '5rem 0',
						height: 'auto',
						alignItems: 'center',
						width: '100%',
					}}
				>
					<h1>Safety Tag OTA</h1>&nbsp;
					<br />
					<span>
						Email: <b>{user?.email}</b>
					</span>
					&nbsp;
					<br />
					<span>
						Customer: <b>{user?.account}</b>
					</span>
					&nbsp;
					<br />
					<span>
						Role: <b>{user?.groups.join(', ')}</b>
					</span>
					&nbsp;
					<br />
				</div>
			</div>
		</Layout>
	);
};

export default HomePage;
